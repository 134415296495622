<template>
  <DefaultLayout :fullWidth="true">
    <div class="privacyPolicy">
      <h1>Privacy Policy</h1>
      <h2>BUZZ INC Privacy Notice</h2>
      <p>Last Updated: July 14, 2022</p>
      <p>Welcome to BUZZ INC! This Privacy Notice describes</p>
      <ul>
        <li>
          How we collect, use, disclose, and protect the personal information
          (defined below) of Drivers (defined below) and other Site users
          (defined below) (“<span class="bold">you</span>“).
        </li>
        <li>
          The types of information we may collect from you or that you may
          provide when you contact us or visit the BUZZ INC Sites (defined
          below).
        </li>
        <li>
          Our practices for collecting, using, maintaining , protecting and
          disclosing that information.
        </li>
      </ul>
      <p>
        If you do not agree with our policies and practices, your choice is not
        to use our BUZZ INC Sites, or any BUZZ INC Services. By accessing or
        using the BUZZ INC Sites or using BUZZ INC’s Services, you indicate that
        you understand, accept, and consent to the practices described in this
        policy. We will from time to time update this Privacy Notice as
        necessary to ensure that it remains accurate and up-to-date. Your
        continued use of the BUZZ INC Sites or Buzz Charging Services after we
        make changes indicates that you accept and consent to those changes, so
        please check the Privacy Notice periodically for updates.
      </p>
      <br />

      <h2>What Does BUZZ INC Do?</h2>

      <p>
        BUZZ INC is an innovative company that facilitates turnkey, home EV
        charging installations across North America by connecting purchasers of
        new electric vehicles (“<span class="bold">Drivers</span>”) with
        qualified EV charging station installers (“<span class="bold"
          >Installers</span
        >”) (the “<span class="bold">Services</span>”). We typically provide the
        Services on behalf of Electric Vehicle Auto Dealers (our “<span
          class="bold"
          >Customers</span
        >”).
      </p>
      <br />
      <p>We connect with Drivers in the following ways:</p>
      <ul>
        <li>
          Our Customers connect us with Drivers so that we may reach out
          directly to Drivers to connect them with Installers;
        </li>
        <li>
          Drivers contact us directly using our BUZZ INC Sites (defined below)
          to find qualified Installers.
        </li>
      </ul>
      <p>
        When providing our Services for our Customers we process personal
        information about individuals on their behalf and at their direction, as
        outlined in this Privacy Notice.
        <span class="underline">
          This Privacy Notice does not explain how our Customers, Installers, or
          other third-parties may process your personal information.
        </span>
      </p>
      <br />
      <h2>What this Privacy Notice Covers</h2>
      <ul>
        <li>
          <span class="bold"
            >Personal Information Collected From BUZZ INC Sites</span
          >: This Privacy Notice applies to all users of BUZZ INC websites (for
          example, "Buzz Charging.com" and web applications each a “Buzz
          Charging Site” and collectively “BUZZ INC Sites“).
        </li>
        <li>
          <span class="bold">Personal Information About Drivers</span>: We
          collect personal information about Drivers in order to provide our
          Service.
        </li>
        <li>
          <span class="bold">Personal Information About Our Installers</span>:
          We may collect personal information about representatives from
          businesses interesting in becoming authorized BUZZ INC Installers.
        </li>
        <li>
          <span class="bold">Personal Information About Our Customers</span>: We
          may collect personal information about representatives from Customers
          as part of our normal business-to-business due diligence and
          communications.
        </li>
      </ul>
      <p>
        This Privacy Notice does not apply to the processing of personal
        information in the context of working as an employee, agent, job
        applicant, contractor, or other member of BUZZ INC’s workforce.
      </p>
      <br />
      <h2>What is Personal Information?</h2>
      <p>
        “Personal information” is information about you which (i) can be used to
        identify, contact or locate you; (ii) can be combined with other
        information that is linked you; or (iii) is defined as “personal data”
        or “personal information” by applicable laws or regulations relating to
        the collection, use, storage or disclosure of information about you.
        Personal information includes contact information (e.g. email address,
        mailing address, or telephone number), online information (e.g. IP
        addresses or other identifiers), and other data which can be used
        individually or in combination with other data to identify an individual
        (such as gender, age, or general location).
      </p>
      <h2>What Personal Information We Collect About You</h2>
      <p>
        We may collect the following categories of personal information from you
        when you use our Services:
      </p>

      <ul>
        <li>
          <span class="bold">Identifiers</span> like your name, address,
          telephone number, email address, unique personal identifier, IP
          address;
        </li>
        <li><span class="bold">Internet activity</span> on BUZZ INC Sites;</li>
        <li>
          <span class="bold">General location data</span> like your street
          address, city, and zip code;
        </li>
        <li>
          <span class="bold">Other personal information</span> that you
          voluntarily provide to us via forms, communications (phone calls,
          emails, messages, etc.);
        </li>
        <li>
          <span class="bold">General information</span> about your vehicle and
          EV charger installation location;
        </li>
      </ul>
      <h2>How We Collect Your Personal Information</h2>
      <p>We collect and process personal information in the following ways:</p>
      <p class="underline">Personal Information You Provide Directly To Us.</p>
      <p>BUZZ INC collects personal information from you when you:</p>
      <ul>
        <li>Provide it to us through one of our BUZZ INC Sites;</li>
        <li>
          Provide it to us using a technology integrations on a third-party
          sites. When we do this, you will see the “Powered by BUZZ INC” or
          similar notice with a link to this Privacy Notice;
        </li>
        <li>Contact us via email or phone to communicate with us;</li>
        <li>Provide your contact information on a form and submit it to us;</li>
        <li>Connect with our social media pages;</li>
        <li>
          Sign up for marketing, an event, a contest, or other offering we may
          present;
        </li>
        <li>Volunteer personal information to us.</li>
      </ul>
      <p class="underline">Personal Information We Collect Automatically.</p>
      <p>
        When you access a BUZZ INC Site or BUZZ INC web application, we
        automatically collect personal information associated with your access
        and use of the site. This information may include your internet protocol
        (IP) address(es), the region or general location where your computer or
        device is accessing the internet, the browser type, operating system and
        other usage information about the use of the Buzz Charging Site(s), such
        as your page-views, referral URLs, etc. Buzz Charging collects and uses
        this type of information to help us understand how users interact with
        the BUZZ INC Sites, to provide you with a more relevant experience, and
        to enhance our business offerings. We have a legitimate interest in
        learning how to improve our BUZZ INC Sites, our Services, customer
        engagement, marketing, and advertising.
      </p>
      <p>
        BUZZ INC and its authorized partners may use cookies and other
        information gathering technologies on BUZZ INC Sites for a variety of
        purposes. These technologies may provide us with personal information,
        information about devices and networks you utilize to access our BUZZ
        INC Sites, and other information regarding your interactions with our
        BUZZ INC Sites. Please review our “Cookies” section below to learn more
        about how we use cookies.
      </p>
      <p class="underline">Personal Information We Collect From Others.</p>
      <p>
        On occasion, BUZZ INC receives personal information about individuals
        from third parties. In particular, we may receive personal information
        about:
      </p>

      <ul>
        <li>Drivers from our Customers when they provide it to us;</li>
        <li>
          Drivers from our Installers when they confirm an installation, service
          request, or other interaction with Drivers;
        </li>
        <li>
          Visitors to BUZZ INC Sites from our advertising, analytics, and
          internet service provider partners related to a visitor’s interactions
          with our BUZZ INC Sites or advertisements;
        </li>
        <li>
          Company and Installer representatives from their respective
          organizations;
        </li>
        <li>
          Other third parties on occasion when provided for legitimate business
          operational purposes.
        </li>
      </ul>

      <p>We don’t buy or sell personal information.</p>
      <h2>Cookies and Other Tracking Technologies</h2>
      <p>
        We use cookies, which are pieces of information that a website transfers
        to an individual’s computer hard drive for record keeping purposes, and
        similar tracking technologies on our website and when providing Buzz
        Charging Sites. Some cookies are set by us and called first-party
        cookies. We also use third-party cookies (which are cookies from a
        domain different than the domain of the website you are visiting) for
        our advertising and marketing efforts.
      </p>
      <p>
        You have a choice about the placement of cookies on BUZZ INC Sites. You
        can use your web browser to directly block all cookies, or just
        third-party cookies, through your browser settings. Using your browser
        settings to block all cookies, including strictly necessary ones, may
        interfere with proper site operation. Guidance on how to control cookies
        in popular browsers are contained in their privacy and/or security
        settings.
      </p>
      <p>
        You can also find additional information on cookie controls and
        advertisement here:
      </p>
      <ul>
        <li>
          <a href="http://optout.networkadvertising.org/?c=1#!%2F">
            Network Advertising Initiative
          </a>
        </li>
        <li>
          <span class="bold">Google</span> allows users to opt out of tracking
          by Google Analytics and Google Analytics Demographics and Interest
          Reporting services. You can adjust your setting
          <a href="https://tools.google.com/dlpage/gaoptout"> here </a>
          , or download the

          <a
            href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en"
          >
            Google Analytics Opt-Out Browser Add-on
          </a>
          .
        </li>
      </ul>
      <h2>How We Use Personal Information We Collect</h2>
      <p>
        In addition to the uses described elsewhere in this Privacy Notice and
        in other notices at or before the point of collection from you, we may
        use your personal information to:
      </p>
      <ul>
        <li>
          improve your browsing experience by personalizing the BUZZ INC Sites;
        </li>
        <li>
          subject to any consent requirements, send information or content to
          you which we think may be of interest to you by post, email, or other
          means and send you marketing communications relating to our business;
        </li>
        <li>develop and improve our Services;</li>
        <li>
          facilitate our business partnerships, customer assistance, quality
          assurance, and other operational purposes;
        </li>
        <li>
          subject to any consent requirements, send promote use of our services
          to you and share promotional and information content with you in
          accordance with your communication preferences;
        </li>
        <li>
          provide other companies with statistical information about our
          individuals. When we do so, this information will not be used to
          identify any individual and will only be shared on an aggregate and
          de-identified basis;
        </li>
        <li>
          contact you about technical, operational, and administrative matters;
        </li>
        <li>
          send information to you regarding changes to our Terms of Use, Privacy
          Policy, or other legal agreements;
        </li>
        <li>investigate and help prevent security issues and abuse; or</li>
        <li>meet legal requirements.</li>
      </ul>

      <h2>How We Share Information With Others</h2>
      <p>
        We (or our service providers on our behalf) may share your personal
        information as required or permitted by law:
      </p>
      <ul>
        <li>
          With Installers in order to facilitate the installation of EV charging
          stations;
        </li>
        <li>With our Customers to provide our Service to them;</li>
        <li>
          With our service providers pursuant to a contract by which they are
          required to protect your personal information, including:
          <ul>
            <li>
              data processing contractors that provide computer services on our
              behalf;
            </li>
            <li>
              web hosting or web development firms that host or develop parts of
              BUZZ INC Sites;
            </li>
            <li>distribution firms that will fulfill your requests;</li>
            <li>billing firms that process your orders;</li>
            <li>mailing firms that will assist us with our mailings;</li>
            <li>marketing firms that will assist us with direct marketing;</li>
            <li>
              data analytics firms that help us conduct customer research and
              analyze users’ interactions with BUZZ INC Sites;
            </li>
            <li>
              service contractors that provide facilities engineering services
              on our behalf.
            </li>
          </ul>
        </li>
        <li>
          With third parties:
          <ul>
            <li>
              to facilitate a direct relationship with you, including in
              connection with any program we administer jointly or on behalf of
              the business partner;
            </li>
            <li>
              to enable electronic communications with you as part of a
              purchase, a program, or other activity in which you have elected
              to participate.
            </li>
          </ul>
        </li>
        <li>
          With a purchaser of BUZZ INC or any of the BUZZ INC affiliates (or
          their assets);
        </li>
        <li>
          To comply with legal orders and government requests, or as needed to
          support auditing, compliance, and corporate governance functions;
        </li>
        <li>
          To combat fraud or criminal activity, and to protect our rights or
          those of our affiliates, users, and business partners, or as part of
          legal proceedings affecting BUZZ INC or our business partners;
        </li>
        <li>
          In response to a subpoena, or similar legal process, including to law
          enforcement agencies, regulators, and courts in the United States and
          other countries where we operate;
        </li>
        <li>With your consent.</li>
      </ul>
      <p class="underline">Sharing Information With Our Service Providers</p>
      <p>
        We use a variety of service provider to provide our Services. For
        example, we use the following:
      </p>
      <ul>
        <li>
          <span class="bold">Google Analytics</span> to provide analytics
          services. For more information about how Google uses this data, you
          can visit:
          <a href="https://www.google.com/policies/privacy/partners/">
            How Google uses data when you use our partners’ sites or apps
          </a>
          . Additionally, you can use the Google Analytics opt-out browser
          add-on to opt-out of having your activity on BUZZ INC Owned Sites
          available to Google Analytics by installing the
          <a
            href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en"
          >
            Google Analytics opt-out browser add-on
          </a>
          . The add-on prevents the Google Analytics JavaScript (gtag.js,
          analytics.js) that is running on websites from sharing information
          with Google Analytics about visit activity.
        </li>
        <li>
          We share data with our hosting provider that is collected when you
          visit one of the BUZZ INC Owned Sites hosted by them, including (i)
          information about your browser, network and device; (ii) web pages you
          visited prior to coming to the website; and (iii) your IP address. Our
          hosting provider needs the data to run these sites, and to protect and
          improve its platform and services. They analyze the data in a
          de-personalized form.
        </li>
      </ul>
      <p class="underline">
        Sharing Information With Our Remarketing Providers
      </p>
      <p>
        BUZZ INC uses Google’s remarketing services to advertise on third party
        websites to previous visitors to BUZZ INC Owned Sites. Third-party
        vendors like Google use cookies to serve ads based on someone’s past
        visits to the BUZZ INC Owned Sites. Any data collected will be used in
        accordance with our own privacy policy, as well as Google’s privacy
        policy. You can opt out of Google’s use of cookies or device identifiers
        by visiting Google’s
        <a href="http://www.google.com/settings/ads"> Ads Settings </a>
        . Alternatively, you can opt out of a third-party vendor’s use of
        cookies by visiting the
        <a href="http://www.networkadvertising.org/managing/opt_out.asp">
          Network Advertising Initiative opt-out page
        </a>
        or control the use of device identifiers by using your
        <a href="https://support.google.com/ads/answer/1660762#mob">
          device’s settings
        </a>
        . Canadian users can opt-out of interest based advertising from certain
        companies using the tools located at:
        <a href="https://youradchoices.ca/en/tools"
          >https://youradchoices.ca/en/tools</a
        >
      </p>
      <h2>Data Security</h2>
      <p>
        The security of your personal information is very important to us. We
        use physical, electronic, and administrative measures designed to secure
        your personal information from accidental loss and from unauthorized
        access, use, alteration, and disclosure.
      </p>
      <p>
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to the BUZZ INC Sites. Any transmission of
        personal information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the BUZZ INC Sites.
      </p>
      <h2>Third Party Links</h2>
      <p>
        BUZZ INC Sites may, from time to time, contain links to and from third
        party websites, such as partners, affiliates, and advertisers. If you
        follow a link to any external website, it will have its own privacy
        policy and we are not responsible for the content or practices of such
        third-party websites or their policies. We are also not responsible for
        any information that you might share with such linked websites. You
        should refer to each website’s respective privacy policy and practices
        before you submit any personal information.
      </p>
      <h2>International Users</h2>
      <p>
        Your personal information will be collected and stored in the United
        States, and subject to the laws of the United States. In these
        circumstances, the governments, courts, law enforcement, or regulatory
        agencies of the United States may be able to obtain access to your
        personal information through the laws of that country. If you reside in
        a country outside the United States, please note that the data
        protection and privacy laws of the United States may not be as
        protective as the laws in your country.
      </p>
      <h2>Your California Privacy Rights:</h2>
      <p>
        The California Consumer Privacy Act (CCPA) requires specific disclosures
        for California residents. Please see our
        <a href="https://qmerit.com/privacy-policy/#ccpa"> CCPA Policy </a>
        for more information.
      </p>
      <h2>Canadian Privacy Rights</h2>
      <p>
        If you are a resident of Canada, under Canadian law have the right to
        request access to and to correct the personal information that Buzz
        Charging holds about you.
      </p>
      <p>
        If you want to review, verify, correct, or withdraw consent to the use
        of your personal information you may send us an email at
        <a href="mailto:support@buzzcharging.com">Support@buzzcharging.com</a>.
        to request access to, correct, or delete any personal information that
        you have provided to us. Please note that if you withdraw your consent,
        we may not be able to provide you with a particular product or service.
        We will explain the impact to you at the time to help you with your
        decision. We may not accommodate a request to change information if we
        believe the change would violate any law or legal requirement or cause
        the information to be incorrect. We may charge you a fee to access your
        personal information, however, we will notify you of any fee in advance.
      </p>
      <p>
        We may request specific information from you to help us confirm your
        identity and your right to access, and to provide you with the personal
        information that we hold about you or make your requested changes.
        Applicable law may allow or require us to refuse to provide you with
        access to some or all of the personal information that we hold about
        you, or we may have destroyed, erased, or made your personal information
        anonymous in accordance with our record retention obligations and
        practices. If we cannot provide you with access to your personal
        information, we will inform you of the reasons why, subject to any legal
        or regulatory restrictions.
      </p>
      <p>
        Where you have provided your consent to the collection, use, and
        transfer of your personal information, you may have the legal right to
        withdraw your consent under certain circumstances. To withdraw your
        consent, if applicable, contact us at
        <a href="mailto:support@buzzcharging.com">support@buzzcharging.com</a>.
      </p>
      <p>
        Please note that if you withdraw your consent we may not be able to
        provide you with the Services or BUZZ INC Sites. We will explain the
        impact to you at the time to help you with your decision.
      </p>
      <p>
        If you are concerned about our response or would like to correct the
        information provided, you may contact our BUZZ INC Privacy Officer at
        <a href="mailto:support@buzzcharging.com">support@BuzzCharging.com</a>.
      </p>
      <h2>Data Collected From Children</h2>
      <p>
        The BUZZ INC Sites are not intended for children under 13 years of age.
        No one under age 13 may provide any information to the Buzz Charging
        Sites. We do not knowingly collect personal information from children
        under 13. If you are under 13, do not use or provide any information on
        the BUZZ INC Sites. If we learn we have collected or received personal
        information from a child under 13 without verification of parental
        consent, we will delete that information. If you believe we might have
        any information from or about a child under 13, please contact us at
        <a href="mailto:support@buzzcharging.com">support@BuzzCharging.com</a>.
      </p>
      <h2>Do Not Track Disclosure</h2>
      <p>
        Regulatory agencies such as the U.S. Federal Trade Commission have
        promoted the concept of Do Not Track as a mechanism to permit Internet
        users to control online tracking activity across websites through their
        browser settings. Since no industry standard has been adopted, we
        currently do not process or comply with any web browser’s “do-not-track”
        signal or other similar mechanism that indicates a request to disable
        online tracking of individual users who visit BUZZ INC Sites.
      </p>
      <h2>Changes to Our Privacy Notice</h2>
      <p>
        We may update this Privacy Notice to reflect changes to our data
        practices. If we make any material changes we will notify you by email
        (if applicable) or by means of a notice on our website or in the Buzz
        Charging Sites prior to becoming effective. We encourage you to
        periodically review this page for the latest data on our privacy
        practices.
      </p>
      <h2>Contact Information for Complaints or Concerns</h2>
      <p>
        If you have any questions or comments about BUZZ INC’s Privacy Notice
        please email your questions or comments to
        <a href="mailto:support@buzzcharging.com">support@BuzzCharging.com</a>.
      </p>
      <p>
        We have procedures in place to receive and respond to complaints or
        inquiries about our handling of personal information, our compliance
        with this policy, and with applicable privacy laws. To discuss our
        compliance with this policy please contact us using the contact
        information listed above.
      </p>
      <h2>BUZZ INC Privacy Policy for California Residents</h2>
      <p>
        Effective Date: July 14, 2022 <br />
        Last Updated: July 14, 2022
      </p>
      <p>
        This Privacy Policy for California Residents (“CCPA Policy”) supplements
        the information contained in BUZZ INC’s Privacy Notice and applies
        solely to all visitors, users, and others who reside in the State of
        California (“consumers” or “you”). We adopt this notice to comply with
        the California Consumer Privacy Act of 2018 (CCPA) and any terms defined
        in the CCPA have the same meaning when used in this Policy.
      </p>
      <p>
        Like our Privacy Notice, this CCPA Policy does not apply to
        workforce-related personal information collected from California-based
        employees, job applicants, contractors, or similar individuals. Where
        noted in this CCPA Policy, the CCPA temporarily exempts personal
        information reflecting a written or verbal business-to-business
        communication (“B2B personal information”) from some its requirements.
      </p>
      <a target="_blank" href="/images/privacy.png">
        <img src="/images/privacy.png" alt="" />
      </a>
      <h2>Your Rights and Choices</h2>
      <p>
        The CCPA provides consumers (California residents) with specific rights
        regarding their personal information. This section describes your CCPA
        rights and explains how to exercise those rights.
      </p>
      <ul>
        <li>
          <span class="bold">Right to Know and Access</span>: You have the right
          to know (and request that we disclose to you) what personal
          information collected, sold, and disclosed about you.
        </li>
        <li>
          <span class="bold">Right to Request Deletion</span>: You have the
          right to request that we delete any of your personal information that
          we collected from you and retained, subject to certain exceptions.
        </li>
        <li>
          <span class="bold"
            >Right to Opt-Out of the Sale of Your Personal Information</span
          >. If you are age 16 or older, you have the right to direct us to not
          sell your personal information at any time. We do not sell the
          personal information of consumers, including those under 16 years of
          age.
        </li>
        <li>
          <span class="bold">Right to Non-Discrimination</span>: We will not
          discriminate against you for exercising any of your CCPA rights.
          Unless permitted by the CCPA, we will not: (i) deny you goods or
          services; (ii) charge you different prices or rates for goods or
          services, including through granting discounts or other benefits, or
          imposing penalties; (iii) provide you a different level or quality of
          goods or services; or (iv) suggest that you may receive a different
          price or rate for goods or services or a different level or quality of
          goods or services.
        </li>
      </ul>
      <p>
        The CCPA temporarily exempts B2B personal information from these
        requirements, so our B2B contacts may not receive substantive responses
        to these requests.
      </p>
      <h2>Exercising Your Rights and Choices</h2>
      <p>
        To exercise your rights to know or delete described above, please submit
        a request by emailing us at
        <a href="mailto:support@buzzcharging.com">support@BuzzCharging.com</a>
        with the subject line: CCPA REQUEST.
      </p>
      <p>
        Only you, or someone legally authorized to act on your behalf, may make
        a request to know or delete related to your personal information. You
        may only submit a request to know twice within a 12-month period.
      </p>
      <p>
        Your request to know or delete must provide sufficient information that
        allows us to reasonably verify you are the person about whom we
        collected personal information or an authorized representative, which
        may include providing us with your name, email address, physical
        address, and other information we may need to verify you identity, which
        varies depending on your relationship with us. We cannot respond to your
        request or provide you with personal information if we cannot verify
        your identity or authority to make the request and confirm the personal
        information relates to you. You do not need to create an account with us
        to submit a request to know or delete. We will only use personal
        information provided in the request to verify the requestor’s identity
        or authority to make it.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or comments about BUZZ INC’s Privacy Notice
        please email your questions or comments to
        <a href="mailto:support@buzzcharging.com">support@BuzzCharging.com</a>.
      </p>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/views/layouts/default";

export default {
  name: "PrivacyPolicy",
  components: { DefaultLayout },
};
</script>

<style lang="scss" scoped>
ul,
p,
h1,
h2 {
  font: normal normal 400 18px/30px Montserrat;
  color: var(--base-text-color);
  margin-bottom: 15px;
}
h1 {
  font-size: 35px;
}
h2 {
  font-size: 25px;
  margin-bottom: 5px;
}
ul {
  padding-left: 50px;
  list-style-type: disc;
  @media (max-width: 500px) {
    padding-left: 25px;
  }
}
.bold {
  font-weight: 600;
  color: #333;
}
.underline {
  text-decoration: underline;
}
img {
  max-width: 100%;
}
</style>
